import $ from 'jquery';

$.fn.cookies = function(options) {

    var settings = $.extend({
        cookieSelector: $(this),
        cookieContainerSelector: '.cookie-container',
        hideCookieClass: 'hide-cookie',
        cookiePopupStateName: 'cookie-popupState',
        cookieStatisticName: 'cookie-statistic',
        cookieMarketingName: 'cookie-marketing',
        cookiePopupState: false,
        cookieAcceptSelector: '#acceptCookies',            // Accept Button (alle optionalen Cookies akzeptieren)
        cookieDeclineSelector: '#declineCookies',
        cookieSettingsSelector: '#openCookieSettings',       // Settings Button (immer aktiv, toggelt Checkbox-Bereich)
        cookieSaveSettingsSelector: '#saveCookieSettings',   // Save settings Button (nur im Settings-Modus sichtbar)
        cookieOpenLinks: 'a[href="#openCookieBar"]',
        cookieExpiry: 14,
        cookiePath: '/'
    }, options );

    // Entfernt den Hash aus der URL
    function removeHash(url) {
        const urlObject = new URL(url);
        urlObject.hash = "";
        return urlObject.toString();
    }

    // Löscht ein Cookie
    function deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=' + settings.cookiePath;
    }

    // Speichert die Auswahl der Checkboxen:
    // Setzt für ausgewählte Checkboxen den Cookie auf true,
    // für nicht ausgewählte auf false.
    function setSelectedCookies() {
        $(settings.cookieContainerSelector + ' input:checkbox:checked').each(function () {
            var value = $(this).val();
            setCookie(value, true);
        });
        $(settings.cookieContainerSelector + ' input:checkbox:not(:checked)').each(function () {
            var value = $(this).val();
            setCookie(value, false);
        });
    }

    // Setzt einen Cookie mit Ablaufdatum
    function setCookie(name, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (settings.cookieExpiry * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=' + settings.cookiePath;
    }

    // Holt den Wert eines Cookies
    function getCookie(name) {
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
    }

    // Blendet die Cookie-Benachrichtigung ein/aus
    function toggleNotice() {
        $(settings.cookieSelector).toggleClass(settings.hideCookieClass);
    }

    // Toggled den Bereich mit den Checkboxen und zeigt bzw. versteckt die Accept/Save settings Buttons
    function toggleSettings() {
        $(settings.cookieContainerSelector).toggleClass(settings.hideCookieClass);
        if (!$(settings.cookieContainerSelector).hasClass(settings.hideCookieClass)) {
            // Settings sichtbar: Zeige Save settings Button, verstecke Accept Button
            $(settings.cookieSelector).find(settings.cookieAcceptSelector).addClass(settings.hideCookieClass);
            $(settings.cookieSelector).find(settings.cookieSaveSettingsSelector).removeClass(settings.hideCookieClass);
        } else {
            // Settings geschlossen: Zeige Accept Button, verstecke Save settings Button
            $(settings.cookieSelector).find(settings.cookieAcceptSelector).removeClass(settings.hideCookieClass);
            $(settings.cookieSelector).find(settings.cookieSaveSettingsSelector).addClass(settings.hideCookieClass);
        }
    }

    // Initialisierung
    function init() {

        // Accept Button: Akzeptiert alle optionalen Cookies
        $(settings.cookieSelector).find(settings.cookieAcceptSelector).on('click', function(){
            setCookie(settings.cookieStatisticName, true);
            setCookie(settings.cookieMarketingName, true);
            setCookie(settings.cookiePopupStateName, true);
            toggleNotice();
            var currentUrlWithParams = window.location.href;
            window.location.href = removeHash(currentUrlWithParams);
        });

        // Save settings Button: Speichert die selektierten Checkbox-Werte (Akzeptiert gewählte, lehnt Rest ab)
        $(settings.cookieSelector).find(settings.cookieSaveSettingsSelector).on('click', function(){
            setSelectedCookies();
            setCookie(settings.cookiePopupStateName, true);
            // Nach dem Speichern: Schließe den Settings-Bereich und zeige den Accept Button wieder
            $(settings.cookieContainerSelector).addClass(settings.hideCookieClass);
            $(settings.cookieSelector).find(settings.cookieAcceptSelector).removeClass(settings.hideCookieClass);
            $(settings.cookieSelector).find(settings.cookieSaveSettingsSelector).addClass(settings.hideCookieClass);
            toggleNotice();
            var currentUrlWithParams = window.location.href;
            window.location.href = removeHash(currentUrlWithParams);
        });

        // Decline Button: Lehnt alle optionalen Cookies ab
        $(settings.cookieSelector).find(settings.cookieDeclineSelector).on('click', function(){
            setCookie(settings.cookiePopupStateName, true);
            if (!$(settings.cookieContainerSelector).hasClass(settings.hideCookieClass)) {
                deleteCookie(settings.cookieStatisticName);
                deleteCookie(settings.cookieMarketingName);
            } else {
                setCookie(settings.cookieStatisticName, false);
                setCookie(settings.cookieMarketingName, false);
            }
            toggleNotice();
            var currentUrlWithParams = window.location.href;
            window.location.href = removeHash(currentUrlWithParams);
        });

        // Settings Button: Toggled den Checkbox-Bereich und die Sichtbarkeit der Accept/Save settings Buttons
        $(settings.cookieSelector).find(settings.cookieSettingsSelector).on('click', function(){
            toggleSettings();
        });

        // Öffnet die Cookie-Benachrichtigung per Link (z. B. in der Navigation)
        $('body').find(settings.cookieOpenLinks).on('click', function(){
            toggleNotice();
        });

        // Falls der Cookie-Popup-Status noch nicht gesetzt wurde, wird die Benachrichtigung angezeigt
        if(getCookie(settings.cookiePopupStateName) !== 'true') {
            toggleNotice();
        }
    }

    return init();
};
